import React from 'react';
import ErrorDisplay from './ErrorDisplay';

const ErrorDisplayAPIOutage = () => (
    <ErrorDisplay
        header="These are not the droids you are looking for"
        content="A mysterious disturbance has occurred in the force...probably something on our end. We are hard at work to fix it!"
        imageUrl="../../svg/Robot_Icon.svg"
    />
);

export default ErrorDisplayAPIOutage;